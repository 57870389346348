import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { titleCase } from '@/utils/index'

const user = {
  state: {
    token: getToken(),
    userId: undefined,
    englishName: '',
    name: '',
    avatar: '',
    roles: [],
    isSuperuser: false, // 是否管理员
    permissions: []
  },

  mutations: {
    SET_IS_SUPER: (state, bol) => {
      state.isSuperuser = bol
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_ENGLISTH_NAME: (state, name) => {
      state.englishName = name
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({ commit }, info) {
      let { loginName, password } = info
      return new Promise((resolve, reject) => {
        login(loginName.trim(), password).then(res => {
          console.log(res, 'res');
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          console.log(error, 'login-error');
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          console.log(res, '用户信息');
          const user = res.data
          let { lastName, firstName } = user
          commit('SET_IS_SUPER', !!user.isSuperuser) // 用户ID
          commit('SET_USER_ID', user.id) // 用户ID
          commit('SET_ENGLISTH_NAME', `${titleCase(firstName)} ${titleCase(lastName)}`)
          commit('SET_NAME', user.loginName)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
