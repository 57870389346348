// 数字千分
export function toThousands(num) {
    var result = [], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
}
//  首字符大写
export function titleCase(str) {
    let tmp = str.toLowerCase()
    tmp = tmp.charAt(0).toUpperCase() + tmp.slice(1)
    // tmp = tmp.slice(0, 1).toUpperCase() + tmp.slice(1)
    // tmp = tmp.substring(0, 1).toUpperCase() + tmp.substring(1)
    return tmp;
}


export function ChangeDateFormat(val) {
    let lth = val?.length
    if (val && lth) {
        return `${val}`.substring(-1, 10)
    }
    return "-";
}


export function toFixedSecond(val) {
    return val.toFixed(2)
}