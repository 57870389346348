import router from './router'
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'
import store from '@/store'

router.beforeEach((to, from, next) => {
    console.log('跳转界面', to);
    if (to.path === '/login') {
        next()
        
    } else if (getToken()) {
        console.log(store.getters.userId, store.getters.isSuperuser, '路由跳转打印store');
        if (!store.getters.userId) {  // 判断是否有userId
            store.dispatch('GetInfo').then(() => {
                console.log(store.getters.userId, store.getters.isSuperuser, '跳转');
                next({ ...to, replace: true })
            }).catch(err => {
                // next({ ...to, replace: true })
                next(`/login`)
                console.log('获取用户信息失败', err);
            })
        } else {
          
            console.log(store.getters.isSuperuser, store.getters.userId);
            let isSuperuser = store.getters.isSuperuser
            if ((to.path === '/stockRights' || to.path === '/user') && !isSuperuser ) { // 特殊判断1
                next({ path: '/home', replace: true })
            } else if (to.path === '/home' && isSuperuser) { // 如果是管理员跳转后台
                next({ path: '/stockRights', replace: true })
            }  else { // 手机端跳转
                next()
            }
        }
    } else {
        next(`/login`)
    }
})

router.afterEach(() => {
})
