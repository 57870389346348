import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export const constantRoutes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/home',
    component: () => import('@/views/home.vue'),
  },
  {
    path: '/center',
    component: () => import('@/views/center.vue'),
  },
  {
    path: '/stockRights',
    component: () => import('@/views/stockRights/index.vue'),
    name: 'StockRights',
    meta: { title: 'Equity management', icon: 'user' }
  },
  {
    path: '/user',
    component: () => import('@/views/user/index.vue'),
    name: 'User',
    meta: { title: 'User management', icon: 'user' }
  },
  {
    path: '/forget',
    component: () => import('@/views/forget.vue'),
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
  },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
