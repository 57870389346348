import Vue from 'vue'
import App from './App.vue'

// 使用 element-ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/element-variables.scss'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
zhLocale.el.pagination = {
  pagesize: ' entries/page', total: `Total {total}`, goto: 'jump to page', pageClassifier: ''
}
Vue.use(Element)
// 使用 bootstrap-vue

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false
// 样式
import './assets/style/common.scss'
import './assets/style/custom.scss'

import router from './router'
import store from './store'

// icon
import IconFontOnline from 'vue-iconfont-online';
Vue.use(IconFontOnline, { url: '//at.alicdn.com/t/c/font_4317110_hjzzs01w2m.css', symbol: 'alibaba-', fontFamily: 'alibaba-icon', fontSize: 16 })

import './permission'

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
